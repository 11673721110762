export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    label: "Leads",
    link: "/leads",
  },
  {
    label: "Callbacks",
    link: "/callbacks",
  },
  {
    label: "Users",
    link: "/employees",
  },
  {
    label: "Status",
    link: "/statuses",
  },
];
