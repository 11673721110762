import {
  GET_LEADS,
  ADD_LEAD,
  EDIT_LEAD,
  GET_LEAD,
  RESET_LEAD,
  LEADS_ERROR,
} from "../types/lead_type";

const initialState = {
  leads: null,
  lead: null,
  total_leads: 0,
  loading: true,
  error: {},
  lead_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADS:
      return {
        ...state,
        leads: payload,
        loading: false,
        error: {},
      };

    case RESET_LEAD:
      return {
        ...state,
        lead: null,
        loading: true,
        error: {},
      };

    case ADD_LEAD:
      return {
        ...state,
        lead_message: payload,
        loading: false,
        error: {},
      };
    case GET_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false,
        error: {},
      };
    case EDIT_LEAD:
      return {
        ...state,
        lead_message: payload,
        loading: false,
        error: {},
      };

    case LEADS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
